/* eslint-disable @wordpress/dependency-group */
import closePopup from '../popup/close-popup';
import { customizeGFormOnError } from '../gform/customize-gform';

const modalControl = document.querySelector('[rel="modal-control"]');

if (modalControl) {
	const modalWindow = document.querySelector('[rel="site-modal"]'),
		body = document.getElementsByTagName('body')[0],
		gForm = modalWindow.querySelector('form'),
		gFormId = parseInt(gForm.id.split('_').pop());

	modalControl.addEventListener('click', (event) => {
		event.preventDefault();
		body.classList.add('compensate-for-scrollbar');
		modalWindow.classList.add('fade-in');
	});
	customizeGFormOnError(gFormId);
	closePopup();
}
