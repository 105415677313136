export const customizeGform = (form) => {
	const typeText = Array.from(form.querySelectorAll('[type="text"]')),
		typeEmail = Array.from(form.querySelectorAll('[type="email"]')),
		typeTextareas = form.querySelectorAll('textarea'),
		errorMessages = form.querySelectorAll('.gfield_validation_message');
	typeText.forEach((el) => {
		addInfocusClass(el);
	});
	typeEmail.forEach((el) => {
		addInfocusClass(el);
	});

	function addInfocusClass(el) {
		if (el.value !== '') {
			if (el.offsetParent) {
				el.offsetParent.childNodes[0].classList.add('is-focus');
			}
		}
		el.addEventListener('focus', (e) => {
			e.target.offsetParent.childNodes[0].classList.add('is-focus');
		});
		el.addEventListener('blur', (e) => {
			if (e.target.value) return false;
			e.target.offsetParent.childNodes[0].classList.remove('is-focus');
		});
	}

	for (const typeTextarea of typeTextareas) {
		typeTextarea.addEventListener('focus', (e) => {
			e.target.offsetParent.childNodes[0].classList.add('is-focus');
		});
		typeTextarea.addEventListener('blur', (e) => {
			if (e.target.value) return false;
			e.target.offsetParent.childNodes[0].classList.remove('is-focus');
		});
	}

	for (const errorMessage of errorMessages) {
		errorMessage.addEventListener('click', function(event) {
			// console.log('click');
			event.target.offsetParent.childNodes[0].classList.add('is-focus');
			event.target.offsetParent.childNodes[1].focus();
			event.target.style.zIndex = '-1';
		});
	}
};

export const customizeGFormOnError = (gFormId) => {
	jQuery(document).on('gform_post_render', function(
		event,
		formId,
		currentPage
	) {
		if (gFormId === formId) {
			const modalForm = event.delegateTarget.forms[`gform_${formId}`];
			const errorMessages = modalForm.querySelectorAll(
				'.gfield_validation_message'
			);

			if (errorMessages.length) {
				customizeGform(modalForm);
			}
		}
	});
};
