/* eslint-disable @wordpress/dependency-group */
import $ from 'jquery';
import { customizeGform } from './customize-gform';

const forms = document.querySelectorAll('[id*="gform_fields_"]'),
	formsSteps = document.querySelectorAll('[id*="gform_page_"]');

if (forms.length) {
	forms.forEach((elem) => {
		customizeGform(elem);
	});
}
if (formsSteps.length) {
	$(document).on('gform_page_loaded', function(event, formId, currentPpage) {
		const pageId = `gform_fields_${formId}_${currentPpage}`,
			pageGfields = document.getElementById(pageId);
		customizeGform(pageGfields);
	});
}
