const stickySidebar = ((window, $) => {
	const mq = window.matchMedia('(min-width: 1024px)');

	const fixedSidebarOnScroll = () => {
		// if (windowWidth) {
		let widthChange, windowWidth;
		/* JavaScript Media Queries */
		if (matchMedia) {
			widthChange = (mediaQuery) => {
				return mediaQuery.matches;
			};
			windowWidth = widthChange(mq);
		}

		const distanceFromTop = $('main.content').offset().top,
			sidebar = $('[rel="sticky-sidebar"]');
		if (windowWidth) {
			$(window).on('scroll', function() {
				const scroll = $(window).scrollTop(),
					sidebarOffset =
						sidebar.offset().top + sidebar.outerHeight();
				if (scroll >= sidebarOffset) {
					sidebar.animate(
						{
							top: '7em',
						},
						1000
					);
					sidebar.addClass('fixed');
				}

				if (distanceFromTop > scroll) {
					sidebar.removeClass('fixed');
				}
			});
		}
	};

	const init = () => {
		fixedSidebarOnScroll();
		$(window).resize(() => {
			fixedSidebarOnScroll();
		});
	};

	return {
		init,
	};
})(window, jQuery);

export default stickySidebar;
