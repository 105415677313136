const closePopup = () => {
	const body = document.getElementsByTagName('body')[0];
	body.addEventListener('click', (event) => {
		if (body.classList.contains('compensate-for-scrollbar')) {
			const targetModal = event.target.classList.contains('site-modal'),
				targetModalContainer = event.target.classList.contains(
					'site-modal-container'
				);
			if (targetModal || targetModalContainer) {
				if (targetModal) {
					body.classList.remove('compensate-for-scrollbar');
					event.target.classList.remove('fade-in');
					if (event.target.getAttribute('rel') === 'site-popup') {
						event.target.remove();
					}
				} else {
					const parentNode = event.target.parentNode;
					body.classList.remove('compensate-for-scrollbar');
					parentNode.remove('fade-in');
					if (parentNode.getAttribute('rel') === 'site-popup') {
						parentNode.remove();
					}
				}
			}
		}
	});
};

export default closePopup;
