/* eslint-disable @wordpress/dependency-group */
import $ from 'jquery';
import './inc/responsive-menus';
import { addMarginToSiteInner, stickyHeader } from './inc/header/sticky-header';
// import './inc/confetti';
import './inc/gform/gform';
import './inc/in-view';
import './inc/modal/open-modal';
import './inc/popup/open-popup';
import stickySidebar from './inc/fixed-sidebar-on-scroll';
import menuSearchForm from './inc/menu-search-form';

$(window).on('load', () => {
	stickyHeader.init();
	menuSearchForm.init();
	if (document.body.classList.contains('single-post')) {
		stickySidebar.init();
	}
});

$(window).on('resize', () => {
	menuSearchForm.init();
});

window.addEventListener('resize', addMarginToSiteInner);
window.addEventListener('DOMContentLoaded', addMarginToSiteInner);
// window.addEventListener('DOMContentLoaded', () => {
// 	console.log('content loaded');
// });
// document.querySelectorAll('*').forEach((el) => {
// 	if (el.offsetWidth > document.documentElement.offsetWidth) {
// 		console.log('Found the worst element ever: ', el);
// 	}
// });
