const menuSearchForm = (() => {
	const init = () => {
		const searchButton = document.querySelector('[rel="js-search-button"]');
		searchButton.addEventListener('click', toggleSearchForm);
	};

	function toggleSearchForm() {
		const isSearchActive = this.getAttribute('aria-pressed');
		const searchFormInput = document.querySelector(
			'.menu-item-type-search .search-form .search-form-input'
		);
		if (isSearchActive === 'true') {
			this.setAttribute('aria-pressed', 'false');
			searchFormInput.blur();
		} else {
			this.setAttribute('aria-pressed', 'true');
			searchFormInput.focus();
		}
		// console.log('this', this.previousElementSibling);
		// console.log('this', this.childNodes);
		// const searchForm = document.querySelector('[rel="js-search-form"]');
		// this.style.opacity = 0;
		this.childNodes[0].classList.toggle('icon-close');
		this.parentNode.classList.toggle('search-active');
		// this.style.opacity = 1;
	}

	return {
		init,
	};
})();

export default menuSearchForm;
