/* eslint-disable @wordpress/dependency-group */
import ScrollReveal from 'scrollreveal';

const nodeList = document.querySelectorAll('[rel="in-view"]'),
	team = document.querySelector('.team'),
	opportunities = document.querySelector('.opportunities');

const slideLeft = {
	delay: 2000,
	distance: '50px',
	duration: 1000,
	origin: 'left',
	opacity: null,
	useDelay: 'onload',
	reset: true,
	mobile: false,
};

const slideRight = {
	delay: 2000,
	distance: '50px',
	duration: 1000,
	origin: 'right',
	opacity: null,
	useDelay: 'onload',
	reset: true,
	mobile: false,
};

Array.from(nodeList).forEach((item) => {
	const columns = item.querySelectorAll('.wp-block-column');
	if (columns.length === 2) {
		if (item.classList.contains('lp-section-reverse')) {
			ScrollReveal().reveal(columns[1], slideLeft);
			ScrollReveal().reveal(columns[0], slideRight);
		} else {
			ScrollReveal().reveal(columns[0], slideLeft);
			ScrollReveal().reveal(columns[1], slideRight);
		}
	}
});

if (team) {
	ScrollReveal().reveal('.member', {
		interval: 300,
		easing: 'ease-in-out',
		scale: 0.8,
		rotate: {
			z: -5,
		},
	});
}

if (opportunities) {
	ScrollReveal().reveal(opportunities, {
		afterReveal: (elem) => {
			elem.classList.add('opportunities-visible');
		},
	});
}
