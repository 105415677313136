/* eslint-disable @wordpress/dependency-group */

import closePopup from '../popup/close-popup';
import {
	customizeGform,
	customizeGFormOnError,
} from '../gform/customize-gform';
import reinitGform from '../gform/reinit_gform';

const buttons = document.querySelectorAll('.wp-block-button'),
	body = document.getElementsByTagName('body')[0];

buttons.forEach((buttonWrap) => {
	const buttonLink = buttonWrap.querySelector('a');
	if (buttonLink.hasAttribute('data-popup')) {
		const endpoint = document.querySelector(
			'link[rel="https://api.w.org/"]'
		).href;

		buttonLink.addEventListener('click', (event) => {
			event.preventDefault();
			const popupID = buttonLink.getAttribute('data-popup');
			const popupHeaders = new Headers();
			popupHeaders.append('pragma', 'no-cache');
			popupHeaders.append('cache-control', 'no-cache');

			const fetchInit = {
				method: 'GET',
				headers: popupHeaders,
			};

			const popUp = fetch(
				`${endpoint}wp/v2/cloudweb-popup/${popupID}`,
				fetchInit
			)
				.then((response) => {
					return response.json();
				})
				.then((postResponse) => {
					// console.log('myJson', postResponse);
					const renderedContent = postResponse.content.rendered,
						popupTemplate = wp.template('popup-template');

					body.insertAdjacentHTML(
						'beforeend',
						popupTemplate({
							content: renderedContent,
						})
					);

					const popupContainer = document.querySelector(
							'[rel="site-popup"]'
						),
						grForm = popupContainer.querySelector('form'),
						grFormId = parseInt(grForm.id.split('_').pop());
					grForm.action = `${window.location.pathname}#gf_${grFormId}`;
					popupContainer.id = `popup-for-${grFormId}`;

					/*
					 * reinit gravity form
					 * */

					body.classList.add('compensate-for-scrollbar');
					popupContainer.classList.add('fade-in');
					return {
						grFormId,
						grForm,
					};
				})
				.then((data) => {
					reinitGform(data.grFormId);
					customizeGform(data.grForm);
					customizeGFormOnError(data.grFormId);
					closePopup();
				})

				.catch((err) => {
					console.log("There's been an error getting the data", err);
				});
		});
	}
});
