const reinitGform = (grFormId) => {
	let gform;
	gform ||
		(document.addEventListener('gform_main_scripts_loaded', function() {
			console.log('gform_main_scripts_loaded');
			gform.scriptsLoaded = !0;
		}),
		window.addEventListener('DOMContentLoaded', function() {
			console.log('DOMContentLoaded');
			gform.domLoaded = !0;
		}),
		(gform = {
			domLoaded: !1,
			scriptsLoaded: !1,
			initializeOnLoaded(o) {
				gform.domLoaded && gform.scriptsLoaded
					? o()
					: !gform.domLoaded && gform.scriptsLoaded
					? window.addEventListener('DOMContentLoaded', o)
					: document.addEventListener('gform_main_scripts_loaded', o);
			},
			hooks: { action: {}, filter: {} },
			addAction(o, n, r, t) {
				gform.addHook('action', o, n, r, t);
			},
			addFilter(o, n, r, t) {
				gform.addHook('filter', o, n, r, t);
			},
			doAction(o) {
				gform.doHook('action', o, arguments);
			},
			applyFilters(o) {
				return gform.doHook('filter', o, arguments);
			},
			removeAction(o, n) {
				gform.removeHook('action', o, n);
			},
			removeFilter(o, n, r) {
				gform.removeHook('filter', o, n, r);
			},
			addHook(o, n, r, t, i) {
				null == gform.hooks[o][n] && (gform.hooks[o][n] = []);
				const e = gform.hooks[o][n];
				null == i && (i = n + '_' + e.length),
					gform.hooks[o][n].push({
						tag: i,
						callable: r,
						priority: (t = null == t ? 10 : t),
					});
			},
			doHook(n, o, r) {
				let t;
				if (
					((r = Array.prototype.slice.call(r, 1)),
					null != gform.hooks[n][o] &&
						((o = gform.hooks[n][o]).sort(function(o, n) {
							return o.priority - n.priority;
						}),
						o.forEach(function(o) {
							'function' !== typeof (t = o.callable) &&
								(t = window[t]),
								'action' == n
									? t.apply(null, r)
									: (r[0] = t.apply(null, r));
						})),
					'filter' == n)
				)
					return r[0];
			},
			removeHook(o, n, t, i) {
				let r;
				null != gform.hooks[o][n] &&
					((r = (r = gform.hooks[o][n]).filter(function(o, n, r) {
						return !!(
							(null != i && i != o.tag) ||
							(null != t && t != o.priority)
						);
					})),
					(gform.hooks[o][n] = r));
			},
		}));

	gform.scriptsLoaded = true;
	gform.domLoaded = true;
	gform.initializeOnLoaded(function() {
		const loaderPinner = `${window.location.origin}/wp-content/plugins/gravityforms/images/spinner.svg`;
		window.gformInitSpinner(5, loaderPinner);
		jQuery(`#gform_ajax_frame_${grFormId}`).on('load', function() {
			const contents = jQuery(this)
				.contents()
				.find('*')
				.html();
			const is_postback = contents.indexOf('GF_AJAX_POSTBACK') >= 0;

			if (!is_postback) {
				return;
			}
			const form_content = jQuery(this)
				.contents()
				.find(`#gform_wrapper_${grFormId}`);

			const is_confirmation =
				jQuery(this)
					.contents()
					.find(`#gform_confirmation_wrapper_${grFormId}`).length > 0;
			const is_redirect = contents.indexOf('gformRedirect(){') >= 0;

			const is_form =
				form_content.length > 0 && !is_redirect && !is_confirmation;

			const mt =
				parseInt(jQuery('html').css('margin-top'), 10) +
				parseInt(jQuery('body').css('margin-top'), 10) +
				100;

			if (is_form) {
				jQuery(`#gform_wrapper_${grFormId}`).html(form_content.html());

				if (form_content.hasClass('gform_validation_error')) {
					jQuery(`#gform_wrapper_${grFormId}`).addClass(
						'gform_validation_error'
					);
				} else {
					jQuery(`#gform_wrapper_${grFormId}`).removeClass(
						'gform_validation_error'
					);
				}
				setTimeout(function() {
					/* delay the scroll by 50 milliseconds to fix a bug in chrome */
					jQuery(document).scrollTop(
						jQuery(`#gform_wrapper_${grFormId}`).offset().top - mt
					);
				}, 50);
				if (window.gformInitDatepicker) {
					window.gformInitDatepicker();
				}
				if (window.gformInitPriceFields) {
					window.gformInitPriceFields();
				}
				var current_page = jQuery(
					`#gform_source_page_number_${grFormId}`
				).val();
				window.gformInitSpinner(5, loaderPinner);
				jQuery(document).trigger('gform_page_loaded', [
					5,
					current_page,
				]);
				window[`gf_submitting_${grFormId}`] = false;
			} else if (!is_redirect) {
				let confirmation_content = jQuery(this)
					.contents()
					.find('.GF_AJAX_POSTBACK')
					.html();
				if (!confirmation_content) {
					confirmation_content = contents;
				}
				setTimeout(function() {
					jQuery('[rel="site-popup"]')
						.find('.wp-block-image')
						.remove();
					jQuery(`#gform_wrapper_${grFormId}`).replaceWith(
						confirmation_content
					);
					jQuery(document).scrollTop(
						jQuery(`#gf_${grFormId}`).offset().top - mt
					);
					jQuery(document).trigger('gform_confirmation_loaded', [
						grFormId,
					]);
					window[`gf_submitting_${grFormId}`] = false;
					wp.a11y.speak(
						jQuery(`#gform_confirmation_message_${grFormId}`).text
					);
				}, 50);
			} else {
				jQuery(`#gform_${grFormId}`).append(contents);
				if (window.gformRedirect) {
					window.gformRedirect();
				}
			}
			jQuery(document).trigger('gform_post_render', [
				grFormId,
				current_page,
			]);
		});
	});
};

export default reinitGform;
