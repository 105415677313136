/**
 * Sticky Header.
 *
 * @package Cloudweb;
 * @author  Valentin Zmaranda
 * @license GPL-2.0+
 */

/**
 * External dependencies
 */
/**
 * External dependencies
 */
/**
 * External dependencies
 */
/**
 * External dependencies
 */
/**
 * External dependencies
 */
/**
 * External dependencies
 */
import Cookies from 'js-cookie';

export const addMarginToSiteInner = () => {
	const root = document.querySelector(':root'),
		siteHeader = document.querySelector('.site-header');
	setTimeout(function() {
		root.style.setProperty(
			'--site-inner-margin-top',
			`${siteHeader.offsetHeight}px`
		);
	}, 200);
};

function _hasNoticeBar($) {
	const noticeBarCookie = Cookies.get('notice-bar');
	const noticeBar = document.querySelector('.notice-bar');
	if (noticeBarCookie === 'closed' || !noticeBar) {
		// $(noticeBar).slideUp();
		return;
	}

	const noticeButtonTrigger = document.querySelector(
		"[rel='close-notification']"
	);

	noticeButtonTrigger.addEventListener('click', function(event) {
		Cookies.set('notice-bar', 'closed', { expires: 30 });
		$(noticeBar)
			.addClass('closed')
			.slideUp();
	});
	//
	// noticeButtonTrigger.on('click', function() {
	// 	Cookies.set('notice-bar', 'closed', { expires: 30 });
	// 	$(noticeBar).slideUp();
	// });
	// if (noticeBar) {
	// 	return true;
	// }
	// return false;
}

export const stickyHeader = ((window, $) => {
	// eslint-disable-next-line @wordpress/no-unused-vars-before-return,no-unused-vars
	_hasNoticeBar($);

	const siteHeader = document.querySelector('.site-header'),
		topMenu = $('.top-menu');
	// noticeBar = $('.notice-bar');

	let initializer = false;

	const fixedWithPrimaryNav = () => {
		const mediaQuery = window.matchMedia('(min-width: 1025px)');
		// console.log('mediaQuery top', mediaQuery.matches);
		const top = mediaQuery.matches === false ? 0 : '-32px';
		// if (mediaQuery.matches === false) {
		// 	return;
		// }
		console.log('the top', top);
		const topMenuHeight = topMenu.outerHeight(true);
		// const noticeBarHeight = noticeBar.outerHeight(true);
		$(window).on('scroll', function() {
			const scroll = $(window).scrollTop();

			// if (scroll >= topMenuHeight + noticeBarHeight) {
			if (scroll >= topMenuHeight) {
				if (initializer === false) {
					$(siteHeader)
						.animate(
							{
								top,
								// noticeBar && !noticeBar.hasClass('closed')
								// 	? '-77px'
								// 	: '-37px',
							},
							100
						)
						.addClass('sticky');
					initializer = true;
				}
			} else if (scroll < topMenuHeight) {
				$(siteHeader)
					.animate({ top: 0 }, 0)
					.removeClass('sticky');
				initializer = false;
			}
		});
	};

	const _moveContentBelowFixedHeader = () => {
		fixedWithPrimaryNav();
	};
	const init = () => {
		// if (!mediaQuery.matches) {
		// 	return false;
		// }
		// if (mediaQuery.matches === false) {
		// 	return false;
		// }

		_moveContentBelowFixedHeader();
		$(window).resize(() => {
			// console.log('mediaQuery', mediaQuery.matches);
			// if (mediaQuery.matches === false) {
			// 	return;
			// }
			initializer = false;
			_moveContentBelowFixedHeader();
		});
	};

	return {
		init,
	};
})(window, jQuery);

// export addMarginToSiteInner;
// export stickyHeader;
