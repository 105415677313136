const topMenu = ((window, $) => {
	const init = () => {
		const mediaQuery = window.matchMedia('(min-width: 1025px)');
		if (!mediaQuery.matches) {
			$('.menu-primary').append(
				'<li class="nav-item-top-menu">' +
					$('.top-menu').html() +
					'</li>'
			);
		}
	};
	return {
		init,
	};
})(window, jQuery);

export default topMenu;
